<template>
  <eden-container>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <eden-periods
          :default-period="'thisweek'"
          :custom-periods="periods"
          :show-custom-period="true"
          @set-period="getStats"
        />
      </template>
    </eden-table-actions>
    <el-table :data="stats[type]" :show-header="false">
      <el-table-column>
        <template slot-scope="scope">
          <span class="font-sm">{{ scope.row.label }}</span>
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template slot-scope="scope">
          <span class="font-sm">{{ scope.row.value }}</span>
        </template>
      </el-table-column>
    </el-table>
  </eden-container>
</template>

<script>
import dashboard from "@/requests/dashboard/laundry";
export default {
  name: "ServiceLaundryBreakdown",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      defaultPeriod: "thisweek",
      pickup: {},
      delivery: {},
      periods: {
        lastmonth: "Last month",
        lastweek: "Last week",
        thisweek: "This week",
        thismonth: "This month",
      },
    };
  },
  computed: {
    title() {
      return `Laundry ${this.formatText(this.type)} Breakdown`;
    },
    stats() {
      console.log(this.pickup, this.delivery, "weeß")
      const pickup = this.pickup
      const delivery = this.delivery
      return {
        pickup: [
          {
            label: "Total orders",
            value: pickup.total_orders,
          },
          {
            label: "Total bags",
            value: pickup.total_bags,
          },
          {
            label: "Total items",
            value:  pickup.total_items,
          },
          {
            label: "Total weight",
            value: `${ pickup.total_weight}kg`,
          },
          {
            label: "Bags for wash and iron",
            value:  pickup.total_bags_wash_and_iron,
          },
          {
            label: "Items for wash and iron",
            value:  pickup.total_items_wash_and_iron,
          },
          {
            label: "Bags for wash and fold",
            value:  pickup.total_bags_wash_and_fold,
          },
          {
            label: "Items for wash and fold",
            value:  pickup.total_items_wash_and_fold,
          },
        ],
        delivery: [
        {
            label: "Total orders",
            value: delivery.total_orders,
          },
          {
            label: "Total bags",
            value: delivery.total_bags,
          },
          {
            label: "Total items",
            value:  delivery.total_items,
          },
          {
            label: "Total weight",
            value: `${ delivery.total_weight}kg`,
          },
          {
            label: "Bags for wash and iron",
            value:  delivery.total_bags_wash_and_iron,
          },
          {
            label: "Items for wash and iron",
            value:  delivery.total_items_wash_and_iron,
          },
          {
            label: "Bags for wash and fold",
            value:  delivery.total_bags_wash_and_fold,
          },
          {
            label: "Items for wash and fold",
            value:  delivery.total_items_wash_and_fold,
          },
        ],
      };
    },
  },
  created() {
    this.getStats({ period: this.defaultPeriod });
    this.getDeliveryBreakdown({ period: this.defaultPeriod });;
  },
  methods: {
    getStats({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      dashboard
        .pickup(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            console.log(data, "service")
            this.pickup = data;
            this.defaultPeriod = period;
            this.loading = false;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },
    getDeliveryBreakdown({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      dashboard
        .delivery(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            console.log(data, "Deklei")
            this.delivery = data;
            this.defaultPeriod = period;
            this.loading = false;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
      }
  },
};
</script>

<style lang="scss" scoped></style>
