<template>
  <div>
    <el-row type="flex">
      <el-col :span="24">
        <eden-page-stats
          class="my-0"
          :loading="loading"
          :title="'Overview'"
          :stats="stats"
          :show-periods="true"
          :periods="periods"
          @set-period="getStats"
        />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <dashboard-laundry-pending-orders />
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="30">
      <el-col :sm="12" :md="12">
        <service-laundry-breakdown :type="'pickup'" />
      </el-col>
      <el-col :sm="12" :md="12">
        <service-laundry-breakdown :type="'delivery'" />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <dashboard-service-feedback :service="'laundry'" />
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="30">
      <el-col :sm="12" :md="12">
        <laundered-items :type="'most_laundered_item'" />
      </el-col>
      <el-col :sm="12" :md="12">
        <laundered-items :type="'least_laundered_item'" />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <service-laundry-pending-qc />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <service-laundry-customer-reports />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ServiceLaundryBreakdown from "@/components/Dashboard/Laundry/ServiceLaundryBreakdown";
import DashboardServiceFeedback from "@/components/Dashboard/DashboardServiceFeedback";
import LaunderedItems from "@/components/Dashboard/Laundry/LaunderedItems";
import ServiceLaundryPendingQc from "@/components/Dashboard/Laundry/ServiceLaundryPendingQc";
import ServiceLaundryCustomerReports from "@/components/Dashboard/Laundry/ServiceLaundryCustomerReports";
import DashboardLaundryPendingOrders from "@/components/Dashboard/Laundry/DashboardLaundryPendingOrders";
import dashboard from "@/requests/dashboard/laundry";

export default {
  name: "DashboardLaundry",
  components: {
    ServiceLaundryCustomerReports,
    ServiceLaundryBreakdown,
    DashboardServiceFeedback,
    LaunderedItems,
    ServiceLaundryPendingQc,
    DashboardLaundryPendingOrders,
  },
  data() {
    return {
      loading: false,
      statsPeriod: "today",
      overview: {},
      periods: {
        today: "Today",
        lastweek: "Last week",
        thismonth: "This month",
      },
    };
  },
  computed: {
    stats() {
      const overview = this.overview;
      let stats = [
        {
          label: "Total laundry subscribers",
          figure: this.formatFigure(overview.total_laundry_subscribers),
        },
        {
          label: "Subscribers served",
          figure: this.formatFigure(overview.total_subscribers_served),
        },
        {
          label: "Total orders",
          figure: this.formatFigure(overview.total_orders),
        },
        {
          label: "One-time orders",
          figure: this.formatFigure(overview.total_one_time_orders),
        },
        {
          label: "Issues reported",
          figure: this.formatFigure(overview.total_issues_reported),
        },
      ];

      if (this.statsPeriod && this.statsPeriod !== "today") {
        stats.push({
          label: "Orders completed in 48 hours",
          figure: this.formatFigure(
            overview.orders_completed_within_time_range,
          ),
        });
        stats.push({
          label: "Orders completed after 48 hours",
          figure: this.formatFigure(
            overview.orders_completed_outside_time_range,
          ),
        });
      }

      return stats;
    },
  },
  created() {
    this.getStats({ period: this.statsPeriod });
  },
  methods: {
    getStats({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      dashboard
        .index(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.overview = data;
            this.statsPeriod = period;
            this.loading = false;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  &:not(:last-child) {
    margin-bottom: 40px !important;
  }
}
</style>
