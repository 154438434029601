<template>
  <eden-container>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <eden-periods
          :default-period="'thismonth'"
          :custom-periods="periods"
          :show-custom-period="true"
          @set-period="setPeriod"
        />
      </template>
    </eden-table-actions>
    <el-table :data="computedIssues" class="hoverable">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.orders">
            <el-table-column width="100">
              <template slot="header">
                <div class="table--header">
                  <span>Order Id</span>
                </div>
              </template>
              <template slot-scope="scope">
                <p class="text-primary">
                  {{ scope.row.order_id }}
                </p>
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template slot="header">
                <div class="table--header">
                  <span>Customer</span>
                </div>
              </template>
              <template slot-scope="scope">
                <p class="text-grey-primary">
                  {{ scope.row.customer }}
                </p>
                <span class="font-xsm text-grey-tertiary">
                  {{ scope.row.gardener }}</span
                >
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <div class="table--header">
                  <span>Number of items with issues</span>
                </div>
              </template>
              <template slot-scope="scope">
                <p>
                  {{ scope.row.items }}
                </p>
              </template>
            </el-table-column>
            <el-table-column width="160">
              <template slot="header">
                <div class="table--header">
                  <span>Status</span>
                </div>
              </template>
              <template slot-scope="scope">
                <eden-tag :value="scope.row.status" />
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template slot="header">
                <div class="table--header">
                  <span>Date reported</span>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="font-sm">
                  {{ formatDate(scope.row.date, "do m, y") }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Issue type</span>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ scope.row.label }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="300">
        <template slot="header">
          <div class="table--header">
            <span>Number of issues</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ scope.row.total }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </eden-container>
</template>

<script>
export default {
  name: "ServiceLaundryPendingQc",
  data() {
    return {
      loading: false,
      issues: [
        {
          id: 1,
          label: "Wrong processing",
          orders: [
            {
              order_id: 983123,
              customer: "Snoop Dogg",
              gardener: "Dr. Dre",
              items: 4,
              status: "unresolved",
              date: "2022-03-18",
            },
            {
              order_id: 584123,
              customer: "Damola Adegoke",
              gardener: "Ope Bello",
              items: 3,
              status: "unresolved",
              date: "2022-03-22",
            },
          ],
        },
        {
          id: 2,
          label: "Missing Items",
          orders: [
            {
              order_id: 983123,
              customer: "Snoop Dogg",
              gardener: "Dr. Dre",
              items: 2,
              status: "unresolved",
              date: "2022-03-18",
            },
            {
              order_id: 584123,
              customer: "Damola Adegoke",
              gardener: "Ope Bello",
              items: 8,
              status: "unresolved",
              date: "2022-03-22",
            },
          ],
        },
        {
          id: 3,
          label: "Bad odour",
          orders: [
            {
              order_id: 983123,
              customer: "Snoop Dogg",
              gardener: "Dr. Dre",
              items: 4,
              status: "unresolved",
              date: "2022-03-18",
            },
          ],
        },
        {
          id: 4,
          label: "Damp items",
          orders: [
            {
              order_id: 983123,
              customer: "Snoop Dogg",
              gardener: "Dr. Dre",
              items: 8,
              status: "unresolved",
              date: "2022-03-18",
            },
            {
              order_id: 584123,
              customer: "Damola Adegoke",
              gardener: "Ope Bello",
              items: 3,
              status: "unresolved",
              date: "2022-03-22",
            },
          ],
        },
        {
          id: 5,
          label: "Damaged items",
          orders: [
            {
              order_id: 983123,
              customer: "Snoop Dogg",
              gardener: "Dr. Dre",
              items: 4,
              status: "unresolved",
              date: "2022-03-18",
            },
            {
              order_id: 584123,
              customer: "Damola Adegoke",
              gardener: "Ope Bello",
              items: 3,
              status: "unresolved",
              date: "2022-03-22",
            },
          ],
        },
        {
          id: 6,
          label: "Mismatched items",
          orders: [
            {
              order_id: 983123,
              customer: "Snoop Dogg",
              gardener: "Dr. Dre",
              items: 2,
              status: "unresolved",
              date: "2022-03-18",
            },
            {
              order_id: 584123,
              customer: "Damola Adegoke",
              gardener: "Ope Bello",
              items: 1,
              status: "unresolved",
              date: "2022-03-22",
            },
          ],
        },
        {
          id: 6,
          label: "Stained items",
          orders: [
            {
              order_id: 983123,
              customer: "Snoop Dogg",
              gardener: "Dr. Dre",
              items: 1,
              status: "unresolved",
              date: "2022-03-18",
            },
            {
              order_id: 584123,
              customer: "Damola Adegoke",
              gardener: "Ope Bello",
              items: 3,
              status: "unresolved",
              date: "2022-03-22",
            },
          ],
        },
        {
          id: 6,
          label: "Rumpled items",
          orders: [
            {
              order_id: 983123,
              customer: "Snoop Dogg",
              gardener: "Dr. Dre",
              items: 4,
              status: "unresolved",
              date: "2022-03-18",
            },
            {
              order_id: 584123,
              customer: "Damola Adegoke",
              gardener: "Ope Bello",
              items: 5,
              status: "unresolved",
              date: "2022-03-22",
            },
          ],
        },
      ],
      defaultPeriod: "thismonth",
      periods: {
        lastmonth: "Last month",
        lastweek: "Last week",
        thisweek: "This week",
        thismonth: "This month",
      },
    };
  },
  computed: {
    computedIssues() {
      return this.issues.map((issue) => {
        return {
          ...issue,
          total: this.sumArray(issue.orders, "items"),
        };
      });
    },
    title() {
      return `${this.sumArray(
        this.computedIssues,
        "total",
      )} Pending Quality Issues`;
    },
  },
  created() {
    //
  },
  methods: {
    setPeriod() {
      //
    },
  },
};
</script>

<style lang="scss" scoped></style>
