import axios from "axios";

export default {
  index(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/laundry/overview?start_date=${start_date}&end_date=${end_date}`
    );
  },

  pickup(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/laundry/pickup-breakdown?start_date=${start_date}&end_date=${end_date}`
    );
  },

  delivery(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/laundry/delivery-breakdown?start_date=${start_date}&end_date=${end_date}`
    );
  },

  launderedItems(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/laundry/laundered-items?start_date=${start_date}&end_date=${end_date}`
    );
  },

  pendingOrder() {
    return axios.get("crm/service-dashboard/laundry/incomplete-orders");
  },

  feedback(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/laundry/feedback-summary?start_date=${start_date}&end_date=${end_date}`
    );
  },

  issues(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/laundry/unresolved-issues?start_date=${start_date}&end_date=${end_date}`
    );
  },
};