<template>
  <eden-container>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <eden-periods
          :default-period="'thisweek'"
          :custom-periods="periods"
          :show-custom-period="true"
          @set-period="launderedItems"
        />
      </template>
    </eden-table-actions>
    <el-table :data="items[type]">
      <el-table-column>
        <template slot="header">
          <span>Item</span>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{ scope.row.item_name }}</span>
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template slot="header">
          <span>Tally</span>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{ scope.row.item_quantity }}</span>
        </template>
      </el-table-column>
    </el-table>
  </eden-container>
</template>

<script>
import dashboard from "@/requests/dashboard/laundry";
export default {
  name: "LaunderedItems",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      items: {
        "most_laundered_item": [],
        "least_laundered_item": [],
      },
      defaultPeriod: "thisweek",
      periods: {
        lastmonth: "Last month",
        lastweek: "Last week",
        thisweek: "This week",
        thismonth: "This month",
      },
    };
  },
  computed: {
    title() {
      return `${this.formatText(this.type === "most_laundered_item" ? "most" : "least")} ${
        this.items[this.type].length
      } Item${this.items[this.type].length > 1 ? "s" : ""} Laundered`;
    },
  },
  created() {
    this.launderedItems({ period: this.defaultPeriod });
  },
  methods: {
    launderedItems({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      dashboard
        .launderedItems(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.items = data;
            this.defaultPeriod = period;
            this.loading = false;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
